import logo from "../assets/img/RSC_Logo_Icon.png";

export default function Header() {
    return (
        <header className="mb-[25px]">
            <nav className="flex flex-row bg-stone-400 relative p-[15px] align-middle justify-end tracking-widest lg:w-[35%] lg:pr-16 xl:w-[475px]">
                <img className="w-[70px] h-[70px] absolute top-[15px] left-[15px]" src={logo} alt="Reece Promo Logo" />
                <ul className="flex flex-row">
                    <li><a href="/" className="uppercase font-montserrat mr-8 xl:text-[21px] xl:mr-16">Home</a></li>
                    <li><a href="#faq" className="uppercase font-montserrat xl:text-[21px]">FAQS</a></li>
                </ul>
            </nav>
        </header>
    )
}