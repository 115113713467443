import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react"
import React from "react";
import Header from "./components/header";
import Hero from "./components/hero";
import Rules from "./components/rules";
import Form from "./components/form";
import Prizes from "./components/prizes";
import Terms from "./components/terms";
import FAQ from "./components/faq";

function App() {
  return (<>
    <Analytics />
    <SpeedInsights />
    <Header />
    <Hero />
    <Rules />
    <Form />
    <Prizes />
    <Terms />
    <FAQ />
  </>);
}

export default App;
