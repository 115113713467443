import prizesBG from "../assets/img/prizes_bg.jpg";
import airplane from "../assets/img/PlaneandPoint.png";
import grandPrize from "../assets/img/GrandPrize_CustomText.png";
import traeger from "../assets/img/Grill_SecondPrize_giveaway.png";
import tv from "../assets/img/TV_ThirdPlace_giveaway.png";

export default function Prizes() {
    return (
        <section id="prizes" className="h-full bg-cover relative p-8 lg:p-16" style={{backgroundImage: `url(${prizesBG})`}}>
            <div className="bg-white opacity-80 absolute w-screen h-full top-0 right-0 bottom-0 left-0"></div>
            <div className="xl:grid xl:grid-cols-2 xl:grid-rows-2 xl:gap-8 xl:grid-rows-none">
                <div className="relative mb-8 xl:mb-0">
                    <img src={airplane} className="absolute top-[-15px] left-[-15px] w-[200px]" alt="Red Flying Airplain" />
                    <img src={prizesBG} className="w-100 block" alt="Cabo Hotel" />
                </div>
                <div className="relative mb-4 xl:mb-0">
                    <h1 className="mb-4 lg:w-full"><span className="hidden">Grand Prize</span><img className="lg:m-auto xl:w-[50%]" src={grandPrize} alt="Grand Prize" /></h1>
                    <p className="uppercase font-montserrat text-center lg:text-[24px] lg:leading-[38px] xl:text-[22px] xl:leading-[32px]"><b>Pack your bags.</b> The grand Prize winner is taking a <span className="text-red-600">trip for two to Los Cabos, Mexico</span>, including round-trip airfare from the U.S. or Canada, <b>three nights</b> at the Grand Fiesta Americana or a simliar hotel, and <b>round-trip airport transfers</b>.</p>
                    <p className="uppercase font-montserrat text-center lg:text-[24px] lg:leading-[38px]">The winner can choose between a snorkeling cruise or a Los Cabos tour, with <b>all meals, snacks, and unlimited drinks</b> included at the property.</p>
                </div>
                <div className="relative mb-4 xl:mb-0">
                    <h2 className="text-red-600 font-bebas uppercase text-[50px] text-center tracking-widest">Second Prize</h2>
                    <img className="mb-2 xl:w-[55%] m-auto" src={traeger} alt="Traeger Smoker" />
                    <p className="uppercase font-montserrat font-semibold text-center">Traeger Ironwood 650 Bundle</p>
                </div>
                <div className="relative">
                    <h2 className="text-red-600 font-bebas uppercase text-[50px] text-center tracking-widest">Third Prize</h2>
                    <img className="mb-2 m-auto" src={tv} alt="Traeger Smoker" />
                    <p className="uppercase font-montserrat font-semibold text-center">Samsung&reg; 65" Crystal 4k UHD Smart Tv</p>
                </div>
            </div>
        </section>
    )
}