import { useState } from 'react';
import formBackground from '../assets/img/form-bg.jpg';

export default function Form() {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [formData, setFormData] = useState({
    business_name: '',
    business_phone_number: '',
    business_email: '',
    invoice_number: '',
    total_spent: '',
  });
  const [submissionStatus, setSubmissionStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!acceptedTerms) {
      setSubmissionStatus('Please accept the terms and conditions.');
      return;
    }

    try {
      const response = await fetch('/api/form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString(),
      });

      if (response.ok) {
        setSubmissionStatus('Form submitted successfully!');
        // Optionally, reset the form or redirect
        setFormData({
          business_name: '',
          business_phone_number: '',
          business_email: '',
          invoice_number: '',
          total_spent: '',
        });
        setAcceptedTerms(false);
      } else {
        const result = await response.json();
        setSubmissionStatus(`Failed to submit form: ${result.message}`);
      }
    } catch (error) {
      setSubmissionStatus(`Error: ${error.message}`);
    }
  };

  return (
    <section id="form" className="h-screen bg-cover relative p-8 z-[1] lg:p-16 lg:h-full" style={{ backgroundImage: `url(${formBackground})` }}>
      <div className="w-screen h-screen absolute top-0 right-0 bottom-0 left-0 z-0 bg-black opacity-70 lg:h-full"></div>
      <h1 className="inline-block uppercase text-white font-montserrat tracking-wide border-b-2 border-b-red-600 pb-4 mb-4 z-10 relative lg:text-[27px] lg:pb-8 lg:mb-8 xl:ml-[110px]">Entry Form</h1>
      <form className="bg-white p-4 z-10 relative lg:p-16 xl:grid xl:grid-cols-2 xl:grid-rows-5 xl:gap-8 xl:max-w-6xl xl:m-auto" onSubmit={handleSubmit}>
        <div className="">
          <label className="text-red-600 font-bebas block lg:text-[27px]">01. Business Name</label>
          <input className="bg-red-300 font-bebas text-white px-2 w-full placeholder:text-white lg:text[28px] xl:h-[40px]" type="text" name="business_name" placeholder="ABC Sign Company" value={formData.business_name} onChange={handleChange} required />
        </div>
        <div className="mt-4 lg:mt-16 xl:mt-0">
          <label className="text-red-600 font-bebas block lg:text-[27px]">02. Business Phone Number</label>
          <input className="bg-red-300 font-bebas text-white px-2 w-full placeholder:text-white lg:text[28px] xl:h-[40px]" type="tel" name="business_phone_number" value={formData.business_phone_number} onChange={handleChange} required />
        </div>
        <div className="mt-4 lg:mt-16 xl:mt-0">
          <label className="text-red-600 font-bebas block lg:text-[27px]">03. Business Email</label>
          <input className="bg-red-300 font-bebas text-white px-2 w-full placeholder:text-white lg:text[28px] xl:h-[40px]" type="email" name="business_email" value={formData.business_email} onChange={handleChange} required />
        </div>
        <div className="mt-4 lg:mt-16 xl:mt-0">
          <label className="text-red-600 font-bebas block lg:text-[27px]">04. Official Reece Invoice Number</label>
          <input className="bg-red-300 font-bebas text-white px-2 w-full placeholder:text-white lg:text[28px] xl:h-[40px]" type="text" name="invoice_number" value={formData.invoice_number} onChange={handleChange} required />
        </div>
        <div className="mt-4 lg:mt-16 xl:mt-0">
          <label className="text-red-600 font-bebas block lg:text-[27px]">05. Total Dollar Amount Spent On Eligible 3M Product</label>
          <input className="bg-red-300 font-bebas text-white px-2 w-full placeholder:text-white lg:text[28px] xl:h-[40px]" type="text" name="total_spent" value={formData.total_spent} onChange={handleChange} required />
        </div>
        <div className="mt-2 lg:mt-16 xl:mt-0">
          <input type="checkbox" name="terms_agreement" className="" onChange={() => setAcceptedTerms(!acceptedTerms)} checked={acceptedTerms} />
          <label className="text-red-600 font-bebas block text-[27px]">I agree to the terms and conditions outlined for the "Reece/3M Getaway Giveaway".</label>
        </div>
        <div className="mt-2 lg:mt-16">
          <p className="font-montserrat text-[#353535] uppercase text-sm">Eligible product includes print and cut media from 3M*.</p>
        </div>
        <div className="mt-2 lg:mt-16">
          <input className="bg-red-600 text-white uppercase w-full py-2 font-montserrat text-sm tracking-widest lg:py-4" type="submit" name="submit" value="Submit" disabled={!acceptedTerms} style={{ opacity: `${acceptedTerms ? 1 : 0.7}` }} />
        </div>
        <p className="mt-4 uppercase text-[10px] font-montserrat tracking-widest text-center"><a href="#terms">"*See terms and conditions for full details</a></p>
        {submissionStatus && <p className="mt-4 text-center text-red-600">{submissionStatus}</p>}
      </form>
    </section>
  );
}
