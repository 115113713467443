import storeBg from "../assets/img/cookie_store_bg.jpg";

export default function FAQ() {
  return (
    <section
      id="faq"
      className="bg-cover relative h-full p-8"
      style={{ backgroundImage: `url(${storeBg})` }}
    >
      <h1 className="font-bebas text-red-600 text-center p-8 text-[50px] uppercase relative z-10">
        FAQ's
      </h1>
      <div className="bg-white opacity-80 absolute w-screen h-full top-0 right-0 bottom-0 left-0"></div>
      <ol className="relative lg:max-w-4xl m-auto">
        <li className="mb-2 text-red-600 uppercase font-montserrat text-center">
          <strong className="text-black block">
            How long is the contest running?
          </strong>{" "}
          From September 9th, 2024 until December 9th, 2024.
        </li>
        <li className="mb-2 text-red-600 uppercase font-montserrat text-center">
          <strong className="text-black block">
            What is the minimum purchase amount for eligibility?
          </strong>{" "}
          $350 of eligible 3M product.
        </li>
        <li className="mb-2 text-red-600 uppercase font-montserrat text-center">
          <strong className="text-black block">
            Where can I find your terms & conditions?
          </strong>{" "}
          <a href="#terms">Click here</a>.
        </li>
        <li className="mb-2 text-red-600 uppercase font-montserrat text-center">
          <strong className="text-black block">
            Do all purchases have to be on the same invoice?
          </strong>{" "}
          Your eligibility is determined by the amount spent. We encourage you
          to order as you see fit. Multiple invoice numbers will not increase
          your chances—only the amount spent will do that.
        </li>
        <li className="mb-2 text-red-600 uppercase font-montserrat text-center">
          <strong className="text-black block">
            What size material is eligible?
          </strong>{" "}
          All sizes are eligible, even cut media, so long as you meet the
          required dollar amount for eligibility.
        </li>
        <li className="mb-2 text-red-600 uppercase font-montserrat text-center">
          <strong className="text-black block">
            How is the winner picked?
          </strong>{" "}
          A winner will be randomly selected by a third party from the list of
          entrants. All winners will be randomly selected on December 17th,
          2024, and announced the same day from all entrants through the
          duration of the giveaway.
        </li>
        <li className="mb-2 text-red-600 uppercase font-montserrat text-center">
          <strong className="text-black block">
            Can I submit an acknowledgement?
          </strong>{" "}
          Entry is based on an official Reece Supply invoice. Acknowledgements
          will not be considered eligible.
        </li>
        <li className="mb-2 text-red-600 uppercase font-montserrat text-center">
          <strong className="text-black block">
            What can I spend the credit on?
          </strong>{" "}
          Any 3M print/cut media including any UMRP products.
        </li>
        <li className="mb-2 text-red-600 uppercase font-montserrat text-center">
          <strong className="text-black block">
            I purchased material the week before the giveaway started, can I
            submit that invoice?
          </strong>{" "}
          No, only invoices created between September 9th, 2024, and December
          9th, 2024 will be eligible.
        </li>
        <li className="mb-2 text-red-600 uppercase font-montserrat text-center">
          <strong className="text-black block">How do I know if I won?</strong>{" "}
          Winners will be contacted on December 17th, 2024 via the email you
          included on your entry form.
        </li>
      </ol>
    </section>
  );
}
