import stamp from "../assets/img/MexicoStamp.png";
import waves from "../assets/img/Waves.png";
import howtoenter from "../assets/img/How To Enter_CustomText.png";

export default function Rules() {
    return (
        <section id="rules" className="h-screen lg:h-full relative p-8 lg:p-16">
            <img src={stamp} className="w-[100px] absolute top-0 left-0 z-[-1] xl:w-[225px]" alt="Mexico Passport Stamp" />
            <img src={waves} className="w-[200px] absolute right-0 bottom-[125px] z-[-1] lg:bottom-0" alt="Waves" />
            <h1 className="mb-[25px] lg:w-full lg:text-center lg:mb-55px"><span className="hidden">How To Enter</span><img src={howtoenter} className="lg:m-auto" alt="How To Enter" /></h1>
            <div>
                <div className="xl:w-full xl:px-[200px]">
                    <span className="font-bebas uppercase tracking-widest text-rose-600 opacity-50 text-[50px] lg:text-[127px]">Step 01.</span>
                    <p className="font-montserrat uppercase font-semibold text-center tracking-widest lg:text-[32px] lg:mt-[-120px] lg:relative xl:px-[100px]">Purchase a minimum of $350 of 3M eligible product in one order with Reece Supply Company.</p>
                </div>
                <div className="xl:w-full xl:px-[200px]">
                    <span className="font-bebas uppercase tracking-widest text-rose-600 opacity-50 text-[50px] lg:text-[127px]">Step 02.</span>
                    <p className="font-montserrat uppercase font-semibold text-center tracking-widest lg:text-[32px] lg:mt-[-120px] lg:relative xl:px-[100px]">Complete the entry form. Every $350 spent on eligible 3M product is one entry!</p>
                </div>
                <div className="xl:w-full xl:px-[200px]">
                    <span className="font-bebas uppercase tracking-widest text-rose-600 opacity-50 text-[50px] lg:text-[127px]">Step 03.</span>
                    <p className="font-montserrat uppercase font-semibold text-center tracking-widest lg:text-[32px] lg:mt-[-120px] lg:relative xl:px-[100px]">Repeat to better your chances of winning!</p>
                </div>
            </div>
        </section>
    )
}