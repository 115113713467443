import palmtree from "../assets/img/PalmTree.png";
import reecesupply from "../assets/img/Reece Supply_CustomText.png"
import brushstroke from "../assets/img/PaintStripe.png";
import corvette from "../assets/img/Car_DiagonalStripeLeft.png";
import cabo from "../assets/img/CaboBeach_DiagonalStripe_Middle.png";
import cookie from "../assets/img/CookieBusiness_DiagonalStripe_Right.png";
import bird from "../assets/img/Birds-Right.png";


export default function Hero() {
    return (
        <section id="hero" className="h-screen relative p-8 overflow-hidden">
            <img id="floating-tree" src={palmtree} className="absolute left-0 bottom-[100px] z-[-1] w-[300px] lg:bottom-[0px]" alt="Red Tree Transparent Background" />
            <h1 className="w-100 text-center z-10 xl:mt-[50px] xl:ml-[100px]">
                <span className="hidden">Reece Supply</span>
                <img src={reecesupply} className="w-60" alt="Reece Promo Company Name" />
            </h1>
            <h2 className="relative z-10 text-[100px] leading-[100px] uppercase font-bebas lg:text-[128px] lg:leading-[128px] xl:ml-[100px] xl:text-[235px] xl:leading-[200px]">Getaway<br /> Giveaway</h2>
            <h3 className="text-[26px] leading-[26px] font-semibold uppercase font-montserrat mb-[100px] text-red-600 bg-cover lg:inline-block lg:mb-[25px] lg:text-[33px] lg:leading-[33px] xl:ml-[100px]" style={{backgroundImage: `url(${brushstroke})`}}>Enter to win a<br/> trip for two to cabo</h3>
            <p><a className="uppercase bg-[#464646] px-14 py-4 text-white font-montserrat lg:inline-block  xl:ml-[100px]" href="#form">Enter Here</a></p>
            <div className="hidden lg:flex absolute top-0 right-[-200px] width=[1000px] flex-row align-middle justify-start xl:right-0">
                <img className="hidden lg:block w-[165px] h-[134px] absolute top-[-30px] right-[355px]" src={bird} alt="Red Bird Flying" />
                <img className="w-[300px] block" src={corvette} alt="Corvette" />
                <img className="w-[300px] block ml-[-85px]" src={cabo} alt="Cabo Beach" />
                <img className="w-[300px] block ml-[-85px]" src={cookie} alt="Cookie Store" />
            </div>
        </section>
    )
}